const data = {
  onboarding: {
    // onBoarded: false,
    stripe_dashboard_url:
      "https://connect.stripe.com/express/onboarding/gx9mp67LY6JI",
    status: "completed",
    onBoarded: true,
  },
};

export default data;
