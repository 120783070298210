import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ClipLoader from "react-spinners/BounceLoader";
import { toast, ToastContainer } from "react-toastify";

import handleOnboarding from "./lib/handleOnboarding";
import CreatePaymentIntent from "./components/CreatePaymentIntent";
import PaymentIntents from "./components/PaymentIntents";

import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const [details, setDetails] = useState(null);
  const [showOverlay, setShowOverlay] = useState(true);
  const [error, setError] = useState(false);
  const [view, setView] = useState("onboarding");

  useEffect(() => {
    handleOnboarding(setShowOverlay, setDetails, setError);
  }, []);

  useEffect(() => {
    if (details?.onBoarded && view === "onboarding") setView("intents");
  }, [details, view]);

  useEffect(() => {
    if (error) {
      toast.error("Unfortunately an error occurred.");
      setError(false);
    }
  }, [error]);

  const renderComponent = () => {
    switch (view) {
      case "onboarding":
        return (
          <>
            In order to start using payment links, you are required to complete
            onboarding process
            {!details?.onBoarded ? (
              <Button onClick={() => window.open(details?.url, "_self")}>
                {details?.status === "pending"
                  ? "Start Onboarding"
                  : "Complete Onboarding"}
              </Button>
            ) : null}
          </>
        );
      case "intents":
        return (
          <PaymentIntents
            {...{
              setView,
              setError,
              setShowOverlay,
              stripe_dashboard_url: details?.stripe_dashboard_url,
            }}
          />
        );
      case "create_intent":
        return (
          <CreatePaymentIntent {...{ setView, setError, setShowOverlay }} />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="orenda-frontend">
      {showOverlay && (
        <div className="d-flex flex-column justify-content-center align-items-center v-100 v-100">
          <p>Please wait...</p>
          <ClipLoader size={30} />
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={showOverlay ? "d-none" : null}>{renderComponent()}</div>
    </div>
  );
};

export default App;
