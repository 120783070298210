import React, { useState, useEffect } from "react";
import { Button, Row, Col, Table } from "react-bootstrap";
import getAllPaymentIntents from "../lib/getAllPaymentIntents";
import ViewPaymentIntent from "./ViewPaymentIntent";

const PaymentIntents = ({
  setView,
  setError,
  setShowOverlay,
  stripe_dashboard_url,
}) => {
  const [paymentIntents, setPaymentIntents] = useState([]);
  const [response, setResponse] = useState(null);
  const [intentView, setIntentView] = useState("list-intents");
  const [data, setData] = useState({});

  useEffect(() => {
    getAllPaymentIntents(setShowOverlay, setPaymentIntents, setError);
  }, []);

  useEffect(() => {
    if (response) {
      getAllPaymentIntents(setShowOverlay, setPaymentIntents, setError);
      setIntentView("list-intents");
      setResponse(null);
    }
  }, [response]);

  const handleRowClick = (intentData) => {
    setData(intentData);
    setIntentView("view-invoice");
  };

  const renderStatus = (status) => {
    if (status === "requires_payment_method") return "open";
    if (status === "succeeded") return "paid";
    return status;
  };

  if (intentView === "view-invoice")
    return (
      <ViewPaymentIntent
        {...{
          setShowOverlay,
          setIntentView,
          setError,
          data,
          setResponse,
          renderStatus,
        }}
      />
    );

  return (
    <div className="orenda-frontend">
      <Row className="m-3">
        <Col lg={12}>
          <h3 className="text-center">Instant Payments</h3>
          <div className="mb-3 d-flex justify-content-between">
            <div>
              {stripe_dashboard_url && (
                <Button
                  className="mx-2"
                  onClick={() => window.open(stripe_dashboard_url, "_blank")}
                >
                  Stripe Dashboard
                </Button>
              )}
            </div>
            <Button onClick={() => setView("create_intent")}>
              Create Payment Link
            </Button>
          </div>

          <Table bordered hover responsive>
            <thead>
              <tr>
                <th style={{ minWidth: 250 }}>Payee Email</th>
                <th style={{ minWidth: 110 }}>Amount</th>
                <th style={{ minWidth: 120 }}>Date</th>
                <th style={{ minWidth: 100 }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {paymentIntents.map((m, i) => (
                <tr key={i} onClick={() => handleRowClick(m)}>
                  <td>{m.receipt_email || ""}</td>
                  <td>
                    {m.amount && m.currency
                      ? `${m.amount / 100} ${m.currency}`
                      : ""}
                  </td>
                  <td>
                    {new Date(m.created * 1000).toLocaleDateString("en-UK")}
                  </td>
                  <td>{renderStatus(m.status) || ""}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default PaymentIntents;
