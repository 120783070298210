import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import createPaymentIntent from "../lib/createPaymentIntent";

const CreatePaymentIntent = ({ setView, setError, setShowOverlay }) => {
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [payerEmail, setPayerEmail] = useState("");
  const [intent, setIntent] = useState("");

  useEffect(() => {
    intent && setView("intents");
  }, [intent, setView]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { amount, description, payerEmail };
    createPaymentIntent(setShowOverlay, setIntent, setError, data);
  };

  return (
    <div className="orenda-frontend">
      <h3 className="text-center my-5">Create Instant Payment Link</h3>
      <Row className="d-flex justify-content-center">
        <Col lg={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Payee Email</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder=""
                value={payerEmail}
                onChange={(e) => setPayerEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Description</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder=""
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Currency</Form.Label>
              <Form.Control
                readOnly
                type="text"
                placeholder=""
                value={"GBP"}
                onChange={(e) => {}}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder=""
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Form.Group>
            <div className="d-flex flex-row justify-content-between">
              <Button
                variant="secondary"
                type="button"
                onClick={() => setView("intents")}
              >
                Back
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default CreatePaymentIntent;
