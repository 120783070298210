import cancelPaymentIntentData from "../mock_data/cancelPaymentIntent.data";

export default function cancelPaymentIntent(
  setShowOverlay,
  setData,
  setError,
  data
) {
  setShowOverlay(true);
  if (!window.OrendaGetResponse || window.isSiteBuilder) {
    setTimeout(() => {
      setData(cancelPaymentIntentData.cancelPayment);
      setShowOverlay(false);
    }, 1000);
    return;
  }

  return window.OrendaGetResponse(
    {
      event_name: "cancelPaymentIntent",
      data: JSON.stringify(data),
    },
    (response) => {
      if (response.error) {
        setShowOverlay(false);
        return setError(true);
      }
      if (!response.stripe?.cancelPayment) return setShowOverlay(false);
      setData(response.stripe.cancelPayment);
      return setShowOverlay(false);
    }
  );
}
