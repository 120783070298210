import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import cancelPayment from "../lib/cancelPaymentIntent";

const ViewPaymentIntent = ({
  setShowOverlay,
  setIntentView,
  setError,
  data,
  setResponse,
  renderStatus,
}) => {
  const isOpen = data.status === "requires_payment_method";

  const handleCancel = () => {
    const reqData = { id: data.id };
    cancelPayment(setShowOverlay, setResponse, setError, reqData);
  };

  return (
    <div className="orenda-frontend">
      <Row className="m-3">
        <Col lg={12}>
          <h3 className="text-center">Payment Details</h3>
          <div className="mb-3 d-flex justify-content-between">
            <Button
              variant="secondary"
              type="button"
              onClick={() => setIntentView("list-intents")}
            >
              Back
            </Button>
            <Button
              variant="danger"
              disabled={!isOpen}
              onClick={() =>
                window.confirm(
                  "Are you sure you want to cancel this Invoice?"
                ) && handleCancel()
              }
            >
              Cancel Payment
            </Button>
          </div>
          <div className="mt-5">
            {data.status && (
              <Row>
                <Col lg={3} className="font-weight-bold">
                  Status
                </Col>
                <Col lg={9}>{renderStatus(data.status)}</Col>
              </Row>
            )}
            {data.description && (
              <Row>
                <Col lg={3} className="font-weight-bold">
                  Description
                </Col>
                <Col lg={9}>{data.description}</Col>
              </Row>
            )}
            {data.amount && data.currency && (
              <Row>
                <Col lg={3} className="font-weight-bold">
                  Invoice Amount
                </Col>
                <Col lg={9}>{`${data.amount / 100} ${data.currency}`}</Col>
              </Row>
            )}
            {data.application_fee_amount && data.currency && (
              <Row>
                <Col lg={3} className="font-weight-bold">
                  Transaction Charges
                </Col>
                <Col lg={9}>
                  {`${data.application_fee_amount / 100} ${data.currency}`}
                </Col>
              </Row>
            )}
            {data.created && (
              <Row>
                <Col lg={3} className="font-weight-bold">
                  Created On
                </Col>
                <Col lg={9}>
                  {new Date(data.created * 1000).toLocaleDateString("en-UK")}
                </Col>
              </Row>
            )}
            {data.paymentLink && (
              <Row>
                <Col lg={3} className="font-weight-bold">
                  Payment URL
                </Col>
                <Col lg={9}>
                  <div
                    className="text-decoration-underline"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(data.paymentLink);
                      toast.success("Link copied to clipboard.");
                    }}
                  >
                    Click to copy URL to clipboard
                  </div>
                </Col>
              </Row>
            )}
            {data.receipt_email && (
              <Row>
                <Col lg={3} className="font-weight-bold">
                  Customer Email
                </Col>
                <Col lg={9}>{data.receipt_email}</Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ViewPaymentIntent;
