import getAllPaymentIntentsData from "../mock_data/getAllPaymentIntents.data";

export default function getAllPaymentIntents(
  setShowOverlay,
  setData,
  setError
) {
  setShowOverlay(true);
  if (!window.OrendaGetResponse || window.isSiteBuilder) {
    setTimeout(() => {
      setData(getAllPaymentIntentsData.getPayments);
      setShowOverlay(false);
    }, 1000);
    return;
  }

  const getTransactions = async (url) => {
    return (
      (await window.fetch(url).then((response) => response.json()))
        ?.transactions || {}
    );
  };

  return window.OrendaGetResponse(
    {
      event_name: "getAllPaymentIntents",
    },
    async (response) => {
      if (response.error) {
        setShowOverlay(false);
        return setError(true);
      }
      if (!response.stripe?.url) return setShowOverlay(false);
      const responseData = await getTransactions(response.stripe.url);
      setData(responseData);
      return setShowOverlay(false);
    }
  );
}
